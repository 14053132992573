import React from 'react'
import './Property.scss'
import PropertyLayout from '../../components/Layout/PropertyLayout'
import PropertyBaseMenu from '../../components/PropertyBaseMenu/PropertyBaseMenu'
import UnitsAndFloorPlans from '../../components/PropertyDetailPage/UnitsAndFloorPlans'

const PropertyPlans = (data: any) => {
  const pageData = data
  return (
    <PropertyLayout active={3} pageData={pageData}>
      <PropertyBaseMenu pageData={pageData} />
      <UnitsAndFloorPlans pageData={pageData} />
    </PropertyLayout>
  )
}

export default PropertyPlans
