import React from 'react'
import './UnitButtons.scss'

const UnitButtons = (props: any) => {
    const { 
        units,
        details,  
        leftCount,
        unitCount,
        rightCount,
        setUnit
    } = props

    return (
        <div className="unitButtons">
            {units.length > 1 && (
                <>
                    <div className="unitButtons__arrows" onClick={() => setUnit(leftCount)}>
                        <i className="la la-arrow-left" />
                        <div className="unitButtons__arrows__label left">
                            <span>{details.brand !== 'avida' && units[leftCount].name}</span>
                            <span>{units[leftCount].type}</span>
                        </div>
                    </div>
                    <div className="unitButtons__pagination">
                        <span>{unitCount}</span>
                        <span>/</span>
                        <span>{units.length}</span>
                    </div>
                    <div className="unitButtons__arrows right" onClick={() => setUnit(rightCount)}>
                        <div className="unitButtons__arrows__label right">
                            <span>{details.brand !== 'avida' && units[rightCount].name}</span>
                            <span>{units[rightCount].type}</span>
                        </div>
                        <i className="la la-arrow-right" />
                    </div>
                </>
            )}
        </div>
    )
}

export default UnitButtons
