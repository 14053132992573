import * as React from 'react'

function LeftArrow(props: { fill?: string }): JSX.Element {
  return (
    <svg width={10} height={17} viewBox="0 0 10 17">
      <path
        d="M.272 7.837L7.846.272A.925.925 0 018.505 0c.25 0 .483.096.658.272l.558.557a.93.93 0 010 1.315l-6.36 6.352 6.367 6.36a.923.923 0 01.272.658c0 .249-.097.482-.272.658l-.558.556a.925.925 0 01-.658.272.925.925 0 01-.659-.272L.272 9.156A.924.924 0 010 8.496c0-.25.096-.484.272-.66z"
        fill={props.fill ? props.fill : '#505050'}
        fillRule="nonzero"
      />
    </svg>
  )
}

export default LeftArrow
