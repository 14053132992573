import BrandLogo from '../../../assets/images/unitsandfloorplans/logo/alpgray.png'
import NameLogo from '../../../assets/images/unitsandfloorplans/parkestates.png'
import FloorPlan from '../../../assets/images/unitsandfloorplans/floorplan.png'
import SampleImage from '../../../assets/images/unitsandfloorplans/sampleImage.png'

const sitePlanImage = 'https://www.enclavealabang.com/wp-content/uploads/2016/11/sdp-map.jpg'

import ALP from '../../../assets/icons/properties/alp.png'
import ALVEO from '../../../assets/icons/properties/alveo.png'
import AVIDA from '../../../assets/icons/properties/avida.png'
import AMAIA from '../../../assets/icons/properties/amaia.png'
import BELLAVITA from '../../../assets/icons/properties/bellavita.png'

import ALP_WHITE from '../../../assets/images/brands/alp.png'
import ALVEO_WHITE from '../../../assets/images/brands/Alveo.png'
import AVIDA_WHITE from '../../../assets/images/brands/Avida.png'
import AMAIA_WHITE from '../../../assets/images/brands/Amaia.png'
import BELLAVITA_WHITE from '../../../assets/images/brands/BellaVita.png'

export interface Sizes {
  name?: string
  sqm?: number
  sqft?: number
}

export interface TotalArea {
  sqm?: number
  sqft?: number
}

export interface Units {
  name?: string
  type?: string
  floorPlanImage?: string[]
  totalArea?: TotalArea
  sizes?: Sizes[]
}

export interface Date {
  date?: number
  month?: string | number
  year?: number
}

export interface UnitsCompletion {
  name?: string
  percentage?: number
}

export interface ConstructionUpdates {
  images?: string[]
  date?: Date
  description?: string
  milestone?: {
    title?: string
    list?: string[]
  }
  unitCompletion?: UnitsCompletion[]
}

export interface PropertyTypes {
  brand?: string
  brandLogo?: string
  name?: string
  nameLogo?: string
  units?: Units[]
  constructionUpdates?: ConstructionUpdates
}

export const BRAND_LOGO = {
  alp: ALP,
  alveo: ALVEO,
  avida: AVIDA,
  amaia: AMAIA,
  bellavita: BELLAVITA
}

export const BRAND_LOGO_WHITE = {
  alp: ALP_WHITE,
  alveo: ALVEO_WHITE,
  avida: AVIDA_WHITE,
  amaia: AMAIA_WHITE,
  bellavita: BELLAVITA_WHITE
}

export const PROPERTY = {
  brand: 'Ayala Land Premier',
  brandLogo: BrandLogo,
  name: 'Park Estates Alviera',
  nameLogo: NameLogo,
  units: [
    {
      name: 'Tower 2',
      type: 'One Bedroom Unit',
      floorPLanImage: [FloorPlan, sitePlanImage, FloorPlan],
      totalArea: {
        sqm: 56,
        sqft: 597
      },
      sizes: [
        {
          name: 'Living/Dining Area',
          sqm: 26,
          sqft: 276
        },
        {
          name: 'Kitchen',
          sqm: 5,
          sqft: 54
        },
        {
          name: 'Bedroom',
          sqm: 12,
          sqft: 130
        },
        {
          name: 'T & B',
          sqm: 5,
          sqft: 50
        },
        {
          name: 'Utility Room',
          sqm: 3,
          sqft: 33
        },
        {
          name: 'Balcony & ACCU',
          sqm: 5,
          sqft: 54
        }
      ]
    },
    {
      name: 'Tower 2',
      type: 'Junior One-Bedroom Unit',
      floorPLanImage: [FloorPlan, FloorPlan],
      totalArea: {
        sqm: 40,
        sqft: 410
      },
      sizes: [
        {
          name: 'Living/Dining Area',
          sqm: 24,
          sqft: 246
        },
        {
          name: 'Kitchen',
          sqm: 3,
          sqft: 34
        },
        {
          name: 'Bedroom',
          sqm: 8,
          sqft: 80
        },
        {
          name: 'T & B',
          sqm: 5,
          sqft: 50
        }
      ]
    },
    {
      name: 'Tower',
      type: 'Studio Unit',
      floorPLanImage: [FloorPlan],
      totalArea: {
        sqm: 25,
        sqft: 254
      },
      sizes: [
        {
          name: 'Living/Dining Area',
          sqm: 15,
          sqft: 150
        },
        {
          name: 'Kitchen',
          sqm: 5,
          sqft: 54
        },
        {
          name: 'T & B',
          sqm: 5,
          sqft: 50
        }
      ]
    }
  ],
  constructionUpdates: [
    {
      images: [SampleImage, SampleImage, SampleImage],
      date: {
        month: 'August',
        year: 2020
      },
      description:
        'Right in the heart of Makati CBD, a wealth of possibilities for residential, retail and office seamlessly integrate the best of what the city has to offer, giving you an edge for success.',
      milestone: {
        title: 'The following works and milestone were achieved for High Street South Corporate Plaza Tower 2:',
        list: [
          'Turnovers are ongoing with 2 units undergoing fit-out',
          'Simultaneously, we are completing the parking levels and common areas in preparation for operation'
        ]
      },
      unitsCompletion: [
        {
          name: 'Tower One',
          percentage: 87.8
        },
        {
          name: 'Tower Two',
          percentage: 50.61
        }
      ]
    }
  ]
}

export const settings = {
  className: 'floorPlanSlider',
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false
}