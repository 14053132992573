/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import loadable from '@loadable/component'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './index.scss'

// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from '@reach/router'
import Slider from 'react-slick'
import { settings } from './data'
import ModalComponent from '../../Modal/Modal'
import UnitDetails from './components/unitDetails'
import HasNoTourFloorPlan from './components/floorPlan/HasNoTourFloorPlan'
import HasTourFloorPlan from './components/floorPlan/HasTourFloorPlan'
import UnitAndFloorPlanContainer from './components/unitAndFloorPlanContainer'
import { getCorrectLogoBrand } from '../../../utils/getCorrectLogoBrand'

const Tour = loadable(() => import('./VirtualTour'))

const UnitsAndFloorPlans = ({ pageData }: any): JSX.Element => {
  const { details, unitsAndFloorPlans } = pageData.pageContext.property
  const { brand } = details
  const { units, panoramas, fullSiteDevPlan } = unitsAndFloorPlans

  const [fullSite, setFullSite] = useState(false)
  const [carousel, setCarousel] = useState<null | any>(null)
  const [unit, setUnit] = useState<number>(0)
  const [activeUnit, setActiveUnit] = useState('')
  const [tour, setTour] = useState(false)

  const logoBrand = getCorrectLogoBrand(brand)
  const currentUrl = useLocation().pathname

  const unitCount = unit + 1
  const leftCount = unit === 0 ? units.length - 1 : unit - 1
  const rightCount = unitCount === units.length ? 0 : unitCount

  const next = (): void => {
    if (carousel) {
      carousel.slickNext()
    }
  }

  const prev = (): void => {
    if (carousel) {
      carousel.slickPrev()
    }
  }

  const handleTypeChange = (value: any): void => {
    setActiveUnit('')
    // eslint-disable-next-line radix
    const newValue = parseInt(value)
    setUnit(newValue)
    // setUnitCount(event.target.value + 1)
  }

  const mappedPlanImages = fullSiteDevPlan.map((img: string, index: number) => {
    return (
      <div key={img} className="floorplan">
        <img src={img} alt="floor plan" />
      </div>
    )
  })

  return (
    <>
      {fullSite && (
        <ModalComponent onClose={(): void => setFullSite(false)}>
          <Slider ref={(c): void => setCarousel(c)} {...settings}>
            {mappedPlanImages}
          </Slider>
        </ModalComponent>
      )}

      <UnitAndFloorPlanContainer>
        {tour ? (
          <HasTourFloorPlan Tour={Tour} panoramas={panoramas} setTour={setTour} />
        ) : (
          <HasNoTourFloorPlan
            logoBrand={logoBrand}
            details={details}
            setCarousel={setCarousel}
            units={units}
            unit={unit}
            fullSiteDevPlan={fullSiteDevPlan}
            setFullSite={setFullSite}
            panoramas={panoramas}
            setTour={setTour}
            next={next}
            prev={prev}
            currentUrl={currentUrl}
          />
        )}
        <UnitDetails
          details={details}
          units={units}
          unit={unit}
          activeUnit={activeUnit}
          setActiveUnit={setActiveUnit}
          handleTypeChange={handleTypeChange}
          unitCount={unitCount}
          leftCount={leftCount}
          rightCount={rightCount}
          setUnit={setUnit}
        />
      </UnitAndFloorPlanContainer>
    </>
  )
}

export default UnitsAndFloorPlans
