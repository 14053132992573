import React from 'react'
import './HasNoTourFloorPlan.scss'

const HasTourFloorPlan = (props: any) => {
    const {
        Tour,
        panoramas,
        setTour
    } = props

    return (
        <div
            className="unit-and-floorplan-left-container fade-in"
            id="virtual-tour"
        >
            <Tour link={panoramas[0]} />
            <div
                className="close-virtual-tour fade-in-bottom dl-8"
                onClick={() => {
                    setTour(false)
                }}
            >
                <i className="la la-chevron-circle-left" />
                <span>Exit Tour</span>
            </div>
        </div>
    )
    }

export default HasTourFloorPlan
