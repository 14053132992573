import React from 'react'
import Dropdown from '../../../../Dropdown/Dropdown'
import { Units } from '../../data'
import './UnitTableHeader.scss'

const UnitTableHeader = (props: any) => {
    const {
        details, 
        units, 
        unit, 
        activeUnit, 
        setActiveUnit, 
        handleTypeChange
    } = props

    return (
        <div className="unitTableHeader">
            <div className="unitTableHeader__name">
                <Dropdown
                    value={details.brand !== 'avida' ? `${units[unit].name} - ${units[unit].type}` : `${units[unit].type}`}
                    defaultValue={details.brand !== 'avida' ? `${units[unit].name} - ${units[unit].type}` : `${units[unit].type}`}
                    name="units"
                    active={activeUnit}
                    handleActive={(active: any) => setActiveUnit(active)}
                >
                    {units.map((unit: Units, index: number) => {
                        return (
                            <li key={index} onClick={() => handleTypeChange(index)}>
                                {details.brand !== 'avida' ? `${unit.name} - ${unit.type}` : `${unit.type}`}
                            </li>
                        )
                    })}
                </Dropdown>

                <a>(Approximate Sizes Only)</a>
            </div>
            <div className="unitTableHeader__sqm">
                <span>SQ.M</span>
            </div>
            <div className="unitTableHeader__sqft">
                <span>SQ.FT</span>
            </div>
        </div>
    )
}

export default UnitTableHeader
