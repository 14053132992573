import React from 'react'

const UnitAndFloorPlanContainer = (props: any) => {
    const { children } = props

    return (
        <div className="unit-and-floorplan-container">
            {children}
        </div>
    )
}

export default UnitAndFloorPlanContainer
