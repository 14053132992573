import React from 'react'
import Slider from 'react-slick'
import FullViewIcon from '../../../../../assets/icons/FullViewIcon'
import { settings } from '../../data'
import FloorPlanButtons from './FloorPlanButtons'

import './HasNoTourFloorPlan.scss'

const HasNoTourFloorPlan = (props: any) => {
  const { logoBrand, details, setCarousel, units, unit, fullSiteDevPlan, setFullSite, panoramas, setTour, next, prev, currentUrl } = props

  const isOnAlpPage = currentUrl.includes('premier')
  const isOnAlveoPage = currentUrl.includes('alveo')

  return (
    <div className="hasNoTourPlan fade-in" id="floor-plan">
      <div className="hasNoTourPlan__floorplan__container">
        <div className="brand-logo">
          <img src={logoBrand} alt="brand logo" style={{ width: '128px' }} />
        </div>
        <div className="name-logo">
          <img className={`${(isOnAlpPage || isOnAlveoPage) && 'invert'}`} src={details.imageLogo} />
        </div>
        <Slider ref={(c) => setCarousel(c)} {...settings}>
          {units[unit].floorPLanImage.map((img: string, index: number) => {
            return (
              <div key={index} className="floorplan">
                <img className={`${isOnAlpPage && 'alp__background'}`} src={img} />
              </div>
            )
          })}
        </Slider>
      </div>
      <div className="hasNoTourPlan__button__container">
        <div
          className="view-full"
          style={{
            cursor: fullSiteDevPlan.length > 0 ? 'flex' : 'default',
            borderWidth: fullSiteDevPlan.length === 0 ? '0px' : '1px'
          }}
          onClick={() => setFullSite(fullSiteDevPlan.length > 0 ? true : false)}
        >
          {fullSiteDevPlan.length > 0 && (
            <>
              <FullViewIcon />
              <span>View Full Site Development Plan</span>
            </>
          )}
        </div>
        <FloorPlanButtons panoramas={panoramas} setTour={setTour} next={next} prev={prev} />
      </div>
    </div>
  )
}

export default HasNoTourFloorPlan
