import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
      <path
        d="M20.85 22a.357.357 0 01-.256-.108.376.376 0 010-.524l.16-.163H4.22l.16.163a.376.376 0 010 .524.356.356 0 01-.512 0l-.769-.787a.373.373 0 01-.106-.266v-.005c0-.127.063-.24.159-.306l.735-.751a.356.356 0 01.512 0 .376.376 0 010 .523l-.16.164h16.535l-.16-.164a.376.376 0 010-.523.356.356 0 01.512 0l.769.786c.072.074.107.17.106.267v.004c0 .128-.063.24-.159.306l-.735.752a.357.357 0 01-.256.108zM1.14 19.428a.36.36 0 01-.3-.162l-.734-.751a.376.376 0 010-.523.356.356 0 01.512 0l.16.163v-16.9l-.16.163a.356.356 0 01-.512 0 .376.376 0 010-.523l.77-.787a.369.369 0 01.565.054l.734.751a.376.376 0 010 .524.356.356 0 01-.512 0l-.16-.164v16.9l.16-.163a.356.356 0 01.512 0 .376.376 0 010 .523l-.77.787a.355.355 0 01-.26.108H1.14zm20.498-1.047H4.14a.366.366 0 01-.362-.37V.371c0-.205.162-.37.362-.37h10.456c.2 0 .362.165.362.37v7.182h6.679c.2 0 .362.165.362.37V18.01c0 .204-.162.37-.362.37zm-6.695-.74h6.333V8.293h-6.333v9.348zm-6.936 0h6.212v-4.957H8.007v4.957zm-3.503 0h2.78v-4.957h-2.78v4.957zm3.141-5.697h6.574V7.923c0-.038.006-.074.016-.108V3.951H4.504v7.993h3.141zm2.079-8.733h4.51V.74h-4.51v2.47zm-5.22 0h4.495V.74H4.504v2.47z"
        fill="#6E6E6E"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default SvgComponent
