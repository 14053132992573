import React from 'react'

import LeftArrow from '../../../../../assets/icons/LeftArrow'
import RightArrow from '../../../../../assets/icons/RightArrow'

import './FloorPlanButtons.scss'

const FloorPlanButtons = (props: any) => {
    const {
        panoramas, 
        setTour, 
        next, 
        prev
    } = props

    return (
        <div className="floorPlanButtons">
            {panoramas.length > 0 && (
                <div
                    onClick={() => {
                        setTour(true)
                    }}
                >
                    <i className="la la-street-view" />
                </div>
            )}
            <div onClick={() => next()}>
                <LeftArrow fill="#ffffff" />
            </div>
            <div onClick={() => prev()}>
                <RightArrow fill="#ffffff" />
            </div>
        </div>
    )
}

export default FloorPlanButtons
