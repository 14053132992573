import * as React from 'react'

function RightArrow(props: { fill?: string }): JSX.Element {
  return (
    <svg width={10} height={17} viewBox="0 0 10 17">
      <path
        d="M9.728 7.837L2.154.272A.925.925 0 001.495 0a.925.925 0 00-.658.272L.279.829a.93.93 0 000 1.315l6.36 6.352-6.367 6.36a.923.923 0 00-.272.658c0 .249.097.482.272.658l.558.556c.175.176.409.272.658.272.25 0 .483-.096.659-.272l7.581-7.572a.924.924 0 00.272-.66.924.924 0 00-.272-.66z"
        fill={props.fill ? props.fill : '#505050'}
        fillRule="nonzero"
      />
    </svg>
  )
}

export default RightArrow
