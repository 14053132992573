import React from 'react'
import { Sizes } from '../../data'
import './UnitTableValues.scss'

const UnitTableValuesContainer = (props: any) => {
    const { units, unit } = props
    
    return (
        <div>
            <div className="unitTableValues">
                <div className="unitTableValues__details">
                    {units[unit].sizes.map((size: Sizes, index: number) => {
                        const { name, sqm, sqft } = size
                        return (
                            <div
                                key={index}
                                className="unitTableValues__details__wrapper"
                            >
                                <div className="name">{name}</div>
                                <div className="sqm">{sqm}</div>
                                <div className="sqft">{sqft}</div>
                            </div>
                        )
                    })}
                </div>
                <div className="unitTableValues__total">
                    <span>TOTAL AREA</span>
                    <span>{units[unit].totalArea.sqm}</span>
                    <span>{parseFloat(units[unit].totalArea.sqft).toFixed(2)}</span>
                </div>
            </div>
        </div>
    )
}

export default UnitTableValuesContainer
