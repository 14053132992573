import React from 'react'
import UnitButtons from './UnitButtons'
import UnitTableHeader from './UnitTableHeader'
import UnitTableValues from './UnitTableValues'
import './index.scss'

const UnitDetails = (props: any) => {
    const {
        details,
        units,
        unit,
        activeUnit,
        setActiveUnit,
        handleTypeChange,
        unitCount, 
        leftCount, 
        rightCount, 
        setUnit
    } = props

    return (
        <div className="unitDetails">
            <div className="unitDetails__container">
                <h3>Unit and Floor Plan</h3>
                <UnitTableHeader
                    details={details}
                    units={units}
                    unit={unit}
                    activeUnit={activeUnit}
                    setActiveUnit={setActiveUnit}
                    handleTypeChange={handleTypeChange}
                />
                <UnitTableValues
                    units={units}
                    unit={unit}
                />
                <UnitButtons
                    units={units}
                    details={details}
                    unitCount={unitCount}
                    leftCount={leftCount}
                    rightCount={rightCount}
                    setUnit={setUnit}
                />
            </div>
        </div>
    )
}

export default UnitDetails
